.logo-img {
  max-height: 100%;
  display: block;
  padding: 10px;
  /*margin: 0 auto;*/
}

.logo-img-menu {
    max-height: 50px;
    /*margin: 0 auto;*/
  }

.logo-bg {
    max-width: 200px;
    background-color: #fff;
    float: "left";
}

.title-bg {
    float: "right";
}

.long-text {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize;
    text-overflow: ellipsis;
}

.cap-text {
    text-transform: capitalize;
}

.product-image {
    width: 90%;
}

.product-cost {
    text-align: right;
    font-size: x-large;
}

.bgimg {
    min-height: 300px;
    background-image: url('./background.jpg');
}

.cat-list {
    background-color:#ffffff;
}

.cat-list:nth-child(odd) {
    background-color:#dedede;
}