input[type="file"] {
  display: none;
}
/*
input[type="file"]::file-selector-button {
  border: 2px solid #2196f3;
  padding: 8px 16px;
  display: inline-block;
  background-color: #2196f3;
  transition: 1s;
  color: #fff;
}

input[type="file"]::file-selector-button:hover {
  background-color: #2196f3;
  border: 2px solid #2196f3;
}
*/

.p-r-5 {
    margin-right: 15px;
}